<template>
  <div class="flex flex-row">
    <div class="flex items-center justify-center w-full">
      <BaseCard class="w-full">
        <div class="lg:mt-40 mt-0">
          <h2 class="mb-5 text-4xl font-light text-center text-gray-800">
            {{ $t("setPassword") }}
          </h2>
          <form @submit.prevent="setPassword">
            <TextField
              type="password"
              v-model="password"
              :label="$t('password')"
              :floating="hasFloatingWidth"
            />
            <TextField
              type="password"
              class="overide-mb"
              v-model="passwordRepetition"
              :label="`${$t('password')} ${$t('doRepeat')}`"
              :floating="hasFloatingWidth"
            />
            <div
              @click="showPasswordInformations=true"
              class="text-gray-600 text-sm hover:text-primary-500 cursor-pointer lg:mt-0 mt-5 underline"
            >
              {{ $t('passwordInformation') }}
            </div>
            <Button type="submit" class="w-full mt-6" :disabled="!passwordsAreEqual" primary>
              {{ $t("confirm") }}
            </Button>
          </form>
        </div>
      </BaseCard>
    </div>
    <DialogModal
          alert
          v-model="modalState.passwordSuccess"
          :primary-text="$t('information')"
          :secondary-text="$t('passwordSuccess')"
          @approve="toLogin"
      />
    <DialogModal
          alert
          v-model="modalState.passwordFailed"
          :primary-text="$t('information')"
          :secondary-text="$t('passwordFailed')"
          @approve="toLogin"
    />
    <DialogModal
          html
          alert
          v-model="showPasswordInformations"
          :primary-text="$t('passwordRequirements')"
          :secondary-text="$t('passwordInformationText')"
          :approveButtonText="$t('close')"
          @approve="showPasswordInformations=false"
    />
  </div>
</template>

<script>

import BaseCard from './components/BaseCard.vue';
import DialogModal from '../../components/DialogModal.vue';

export default {
  name: 'Password',
  components: { BaseCard, DialogModal },
  data() {
    return {
      password: '',
      passwordRepetition: '',
      showPasswordInformations: false,
    };
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    passwordsAreEqual() {
      return this.password === this.passwordRepetition && this.validatePassword(this.password);
    },
    modalState: {
      get() {
        return this.$store.state.memberArea.modalState;
      },
      set(value) {
        this.$store.commit('setModalState', value);
      },
    },
  },
  methods: {
    setPassword() {
      this.$store.commit('setPassword', this.password);
      this.$store.dispatch('verify');
    },
    toLogin() {
      this.modalState.passwordFailed = false;
      this.modalState.registrationSuccess = false;
      // this.$store.dispatch('logout');
      this.$router.push({ name: 'member-login' });
    },
    validatePassword(password) {
      return /[A-Z]/.test(password) // uppercase
           && /[a-z]/.test(password) // lowercase
           && /[0-9]/.test(password) // digit
           && password.length >= 8; // more than 8
    },
  },
  mounted() {
    this.$store.dispatch('logout');
    if (this.$route.query.activate_id === undefined) {
      this.$router.push({ name: 'member-login' });
    } else {
      this.$store.commit('setToken', this.$route.query.activate_id);
    }
  },
};
</script>

<style scoped>
  .overide-mb {
    margin-bottom: 0px;
  }
</style>
